import celebBadge from "src/assets/img/celeb_badge.svg";
import { getAgeUser } from "src/util/Utils";
import { Button } from "@mui/material";
import { green } from "@mui/material/colors";
const ColumnListCelebDonate = (handleAccept, handleDetail) => {
  const columns = [
    {
      field: "id",
      hide: true,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "nickname",
      headerName: "셀럽 닉네임(ID)",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => handleDetail(params.row)}
          >
            <p>
              {params.row.nickname}
              <span>
                <img src={celebBadge} />
              </span>
            </p>
            <p>({params.row.identified_id})</p>
          </div>
        );
      },
    },
    {
      field: "age",
      headerName: "나이",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{getAgeUser(params.row.birthday)}</p>
          </div>
        );
      },
    },

    {
      field: "gender",
      headerName: "성별",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "donate",
      headerName: "보유코인",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
    },
    // {
    //   field: "total_revenue",
    //   headerName: "환전코인",
    //   flex: 1,
    //   minWidth: 100,
    //   sortable: false,
    //   headerAlign: "center",
    // },
    // {
    //   field: "intermediary_fee",
    //   headerName: "환전율",
    //   flex: 1,
    //   minWidth: 100,
    //   sortable: false,
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     return (
    //       <div style={{ textAlign: "center" }}>
    //         <p>{params.row.intermediary_fee}%</p>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "other",
      headerName: "비고",
      flex: 1,
      minWidth: 250,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            {
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                  variant="outlined"
                  sx={{
                    background: green[100],
                    width: 112,
                    height: 42,
                    borderRadius: "0.5rem",
                    border: `1px solid ${green[400]}`,
                    color: "black",
                    "&:hover": {
                      background: green[200],
                      border: `1px solid ${green[400]}`,
                    },
                  }}
                  onClick={() => handleAccept([params.row.id])}
                >
                  신청
                </Button>
              </div>
              // : (
              //   params.row.state === "" && (

              //   )
              // )
            }
          </div>
        );
      },
    },
  ];
  return columns;
};

export default ColumnListCelebDonate;
