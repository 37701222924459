import Tooltip from "@mui/material/Tooltip";
import celebBadge from "src/assets/img/celeb_badge.svg";
import { checkStatusPay, getAgeUser, LIMIT_CONDITION } from "src/util/Utils";
import { Button } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { convertTextToKo } from "src/util/convert";
const MyTableColumns = (handleAccept, handleDeny) => {
  const columns = [
    {
      field: "id",
      hide: true,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "no",
      headerName: "고유번호",
      headerAlign: "center",
      sortable: false,
    },
    ,
    {
      field: "nickname",
      headerName: "닉네임(ID) ",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>
              {params.row.nickname}{" "}
              <span>
                <img src={celebBadge} />
              </span>
            </p>
            <p>({params.row.identified_id})</p>
          </div>
        );
      },
    },
    {
      field: "age",
      headerName: "나이",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{getAgeUser(params.row.birthday)}</p>
          </div>
        );
      },
    },

    {
      field: "sex",
      headerName: "성별 ",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{params.row.sex == "MALE" ? "남자" : "여자"}</p>
          </div>
        );
      },
    },
    {
      field: "donated_money",
      headerName: "보유코인",
      renderCell: (params) => {
        return <div>{params.row.donated_money.toLocaleString()}</div>;
      },
      flex: 1,
      minWidth: 200,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "intermediary_fee",
      headerName: "수수료율(%)",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{params.row.intermediary_fee}%</p>
          </div>
        );
      },
    },
    {
      field: "money_purchase",
      headerName: "정산 가능액",
      flex: 1,
      minWidth: 250,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>
              {Math.ceil(
                Math.floor((params.row.donated_money * (params.row.intermediary_fee / 100)) / LIMIT_CONDITION) * LIMIT_CONDITION
              ).toLocaleString()}
            </p>
          </div>
        );
      },
    },
    {
      field: "status_account",
      headerName: "상태",
      flex: 1,
      minWidth: 200,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{params.row.status_account == "NORMAL" ? "정상" : params.row.status_account == "BLOCK" ? "계정정지" : "탈퇴로 표기"}</p>
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "신청날짜",
      flex: 1,
      minWidth: 150,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.created_at}>
              <p>{params.row.created_at}</p>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "processing_date",
      headerName: "처리날짜",
      flex: 1,
      minWidth: 150,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.processing_date}>
              <p>{params.row.processing_date}</p>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "refund_coin",
      headerName: "미정산 잔액",
      flex: 1,
      minWidth: 100,
      sortable: false,

      headerAlign: "center",
    },
    {
      field: "state",
      headerName: "정산상태",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return <p>{convertTextToKo(params?.row?.state)}</p>;
      },
    },
    {
      field: "other",
      headerName: "비고",
      flex: 1,
      minWidth: 250,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              textAlign: "center",
              width: "100%",
            }}>
            {
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                {params.row.accept ? (
                  <Button
                    variant="outlined"
                    sx={{
                      background: green[100],
                      width: 112,
                      height: 42,
                      borderRadius: "0.5rem",
                      border: `1px solid ${green[400]}`,
                      color: "black",
                      "&:hover": {
                        background: green[200],
                        border: `1px solid ${green[400]}`,
                      },
                    }}
                    onClick={() => handleAccept([params.row.id])}>
                    신청
                  </Button>
                ) : (
                  params.row.state === "PROCESS" && (
                    <Button
                      variant="outlined"
                      sx={{
                        background: red[100],
                        width: 112,
                        height: 42,
                        borderRadius: "0.5rem",
                        border: `1px solid ${red[400]}`,
                        color: "black",
                        "&:hover": {
                          background: red[200],
                          border: `1px solid ${red[400]}`,
                        },
                      }}
                      onClick={() => handleDeny([params.row.id])}>
                      취소
                    </Button>
                  )
                )}
              </div>
              // : (
              //   params.row.state === "" && (

              //   )
              // )
            }
          </div>
        );
      },
    },
  ];
  return columns;
};

export default MyTableColumns;
