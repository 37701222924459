import React from "react";

const ListUser = React.lazy(() => import("./Pages/ListUser"));

const routes = [
  {
    path: "/",
    exact: true,
    name: "ListUser",
    component: ListUser,
    index: 0,
  },
];

export default routes;
