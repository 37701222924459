import React from "react";

const PhoneInput = ({ field, form, className, placeholder }) => {
	return (
		<>
			<input
				placeholder={placeholder ? placeholder : ""}
				className={className ? className : ""}
				pattern="^-?[0-9]\d*\.?\d*$"
				onChange={(e) => form.setFieldValue(field.name, e.target.value)}
			/>
		</>
	);
};

export default PhoneInput;
